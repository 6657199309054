// Here you can add other styles

.card-header {
    font-size: 1.2rem;
    line-height: 1;
}

.btn-pill {
    border-radius: 50em !important;
}

.acc-details{
    width: 325px !important;
}

.acc-details a{
    width: 137px !important;
    margin-right: 2px;
}
.acc-details li.d-flex{
    flex-wrap: wrap;
}
.breadcrumb {
    background-color: white !important;
}

.bg-secondary {
    background-color: #6c757d!important;
}

input{
    &.check-box{
        max-width: 20px !important;
        & + label{
            font-weight: 600;
        }
    }
}

.btn-qr {
    font-size: 30px;
    height: 100px;
    width: 100%;
    color: #fff;
    background-color: #1ab4ef;
    border: none;
    border-radius: 3px;
    padding: 10px 15px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2), 0 3px 3px rgba(0,0,0,0.2);
    @media (max-width: 480px) {
        width: 338px;
    }
    @media (max-width: 400px) {
        width: 361px;
    }
}
.btn-qr:focus {
    outline: none;
}
.btn-qr:hover {
    background-color: #1ab3ef9d;
}

.imageStyle  {
    width: 350px;
    height: 350px;
    border: 1px solid black;
  }

  #user-table-1{
    @media (max-width: 480px) {
        display: none;        
    }
    @media (max-width: 767px) {
      display: none; 
  }
}

#user-card-1{
  @media (min-width: 768px) {
    display: none;
}
}