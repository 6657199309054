@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}

@font-face {
    font-family: myRmmFont;
    src: url(assets/fonts/RobotoMono-SemiBold.ttf);
}
  
.rmm-font-family {
    font-family: myRmmFont;
    font-size: 1.2rem;
}